import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Google Material Design 3 Web UI Kit | Uizard"
    category="Website Templates"
    url="/templates/website-templates/marketplace-website/"
    metaDescription="Our Google Material Design 3 web UI kit is a lightweight and modular front-end framework for designing in the style of Google Material Design 3."
    description="
    h2:Uizard’s Google Material Design 3 web UI kit
    <br/>
    Our Google Material Design 3 web UI kit is a lightweight and modular front-end framework for designing fast and powerful web interfaces in the style of Google Material Design 3. Our Material Design 3 web UI kit comes with all the components you need to kickstart your next <a:https://uizard.io/templates/website-templates/>web design</a> project. Simply sign up to Uizard and bring your vision to life.
    <br/>
    h3:Design a website or app to reflect the design philosophy of Material Design 3
    <br/>
    Looking to design a web app mockup that reflects Google Material Design 3? Then working in Uizard is the perfect choice for you. Uizard’s Material Design 3 web UI kit comes packed with all the components you need to bring your web vision to life. <a:https://uizard.io/ux-design/>UX design</a> just got a whole lot easier.
    <br/>
    h3:Collaborate and iterate with your team instantly
    <br/>
    Bring your idea to life with your team like never before with Uizard's powerful, real-time collaboration features. Add and reply to comments, edit alongside other internal stakeholders, you can even share previews of your Uizard projects with external stakeholders with the click of a button.
    "
    pages={[
      "All the pre-built Material 3 components you need to design your website from initial sign-up components right through to navigational, transactional, and content elements",
      "Adapt, update, and tweak your Material Design 3 designs to suit your own web design project with Uizard's easy-to-use drag-and-drop editor"
    ]}
    projectCode="K7q8M9rKQ8t4m1y1rK9g"
    img1={data.image1.childImageSharp}
    img1alt="google material ui 3 website design template cover screen"
    img2={data.image2.childImageSharp}
    img2alt="google material ui 3 website design components"
    img3={data.image3.childImageSharp}
    img3alt="google material ui 3 website design ecommerce screens"
    img4={data.image4.childImageSharp}
    img4alt="google material ui 3 website design onboarding screens"
    img5={data.image5.childImageSharp}
    img5alt="google material ui 3 website design profile screens"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/material-design-3-ui-kit/google-material-3-ui-kit-web-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/material-design-3-ui-kit/google-material-3-ui-kit-web-components.jpeg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/material-design-3-ui-kit/google-material-3-ui-kit-web-ecommerce.jpeg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/material-design-3-ui-kit/google-material-3-ui-kit-web-onboarding.jpeg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/material-design-3-ui-kit/google-material-3-ui-kit-web-profile.jpeg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
